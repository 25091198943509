
/* eslint-disable */

import { ref, reactive, onMounted, onBeforeMount, defineExpose } from "vue";
import { useRouter } from 'vue-router'
import { helper as $h } from "@/utils/helper"
import CloudFun, { Condition, Operator, Sorting, defineComponent } from "@cloudfun/core";
import { OrderQueryData } from "@/views/order/types";

import Swal from "sweetalert2";
import FullCalendar, { Calendar, EventApi } from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import resourceTimelineMonth from "@fullcalendar/resource-timeline"
import VacationEventModal from "./calendar-modal/VacationEventModal.vue"
import AppointmentModal from "./calendar-modal/AppointmentModal.vue";


export default defineComponent({
  components: {
    AppointmentModal,
    VacationEventModal,
    FullCalendar,
  },
  setup() {
    const model = CloudFun.current?.model,
      $t = CloudFun.current?.i18n.global.t,
      application = CloudFun.current,
      router = useRouter(),
      enums = model!.enums;
    const queryData = reactive<OrderQueryData>({
      store: "",
      employee: "",
      startDate: "",
      endDate: "",
    });

    const today = ref<string>("");
    // Calendar reference
    const calendarRef = ref<Calendar>();
    const calendarApi = ref();
    const dataLoaded = ref(true);

    const storePeformance = reactive({ customer: 0, amount: 0 }),
      selectedStylist: any = ref(null),
      store = reactive({
        Id: '',
        Name: '',
        Phone: '',
        Email: '',
        ContactPerson: '',
        BookingStartTime: '',
        BookingEndTime: '',
        BookingUnitTime: '',
        BookingIntervalTime: '',
        Address: {
          Line: '',
        },
        Week: [],
        RecessPeriod: { Durations: [] },
        BookingStartTimeDP: '',
        BookingEndTimeDP: '',
      });
    let currentDate: any = "",
      stylists: any[] = [],
      stylistIds = null,
      // storeId = this.$store.getters["auth/defaultStoreId"],
      // stylists =[{ Value =-1, Name ="全部", hide =false }],
      // selectedStylist =-1,
      reserveVisible = ref(false),
      orderId = "",
      specialHours = {},
      orderModalShow = false,
      isReservable = true;


    const allResources: any = ref([]),
      recessDurationEvents: any = [];

    const appointmentModal = reactive({
      title: "預約內容",
      visible: false,
      data: {}
    }),
      vacationEventModal = reactive({
        title: "",
        visible: false,
        data: {}
      }),
      selectedTime: any = ref(""),
      selectedEmployee: any = reactive({});



    const calendarOptions: any = reactive({
      eventDurationEditable: true,
      expandRows: true,
      contentHeight: 'auto',
      plugins: [
        dayGridPlugin,
        interactionPlugin,
        timeGridPlugin,
        resourceTimelineMonth,
        resourceTimelinePlugin,
      ],
      resourceAreaWidth: 100,
      resourceAreaHeaderContent: "設計師",
      customButtons: {
        // orderButton: {
        //   text: "設計師排序",
        //   click: function() {
        //     self.listVisible = true;
        //   }
        // }
      },
      headerToolbar: {
        left: "",
        center: "title",
        // right: "resourceTimeGridDay",
      },
      buttonText: {
        today: '今日',
        week: '週',
        day: '日',
        month: '月'
      },
      locale: "zh-tw",
      initialView: "resourceTimelineMonth",
      events: [],
      resources: [],
      resourceOrder: "index,order",
      businessHours: [],
      selectable: true,
      selectConstraint: "businessHours",
      droppable: false,
      schedulerLicenseKey: "CC-Attribution-NonCommercial-NoDerivatives",
      eventContent: function (arg) {
        if (arg.event) {
          return {
            html: `<i class="mr-1 ${arg.event.extendedProps.icon}"></i>${arg.event.title
              }<br>${arg.event.extendedProps.content ?? ""}`,
          };
        }
      },
      eventClassNames: function (arg) {
        return arg.event.extendedProps.class;
      },
      datesSet: function (info) {
        console.log("Dates set", info)
        // if (!monthMode) {
        if (currentDate !== info.startStr) {
          // console.log("Date change", info);

          currentDate = info.startStr;
          queryData.startDate = $h.formatDate(info.start, "yyyy/MM/dd HH:mm");
          queryData.endDate = $h.formatDate($h.getEndTimeOfDay(info.endStr), "yyyy/MM/dd HH:mm");
          
          refreshCal();
        } else {
          return;
        }
        // }
      },
      eventClick: function (info) {
        console.log("Event Clicked!", info);
        var eventId = info.event.extendedProps.eventId;
        if (info.event.extendedProps.eventType !== 0
          &&
          info.event._def.title !== "休息時段") {
          model?.dispatch("employeeEvent/find", eventId).then((resp) => {
            var reserveObject = resp;
            reserveObject.EventId = eventId;
            // reserveObject.IsNew = false;
            var re = stylists.find((e) => e.EId === reserveObject.EmployeeId);
            console.log("RE", re)
            reserveObject.Employee = re.Name
            reserveObject.StartTime = $h.formatDate(new Date(reserveObject.StartTime), "yyyy/MM/dd");
            console.log(reserveObject.Employee);
            vacationEventModal.visible = true;
            vacationEventModal.data = reserveObject;
          });
          return;
        } else if (info.event._def.title == "休息時段") return;
        // }
      },
      eventMouseLeave: function (info) {
        isReservable = true;
      },
      selectOverlap(event) {
 
        return true;
      },

      dateClick: function (dateinfo) {
        var data = {
          EmployeeId: dateinfo.resource.id,
          type: 2, // PersonalLeave
          // PartiionId: self.partitionId,
          Description: "休假",
          StartTime: $h.getStartTimeOfDay(dateinfo.date),
          EndTime: $h.getEndTimeOfDay(dateinfo.date),
        };
        model?.dispatch("employeeEvent/insert", data).then((resp) => {
          // modalVisible = false;
          queryData.startDate = ""
          refreshCal();
        })

      },
      dayCellWillUnmount: function (arg) {
        // console.log('datcell', arg);
        if (arg.view.type !== "dayGridMonth") {
          arg.el.style.backgroundColor = arg.resource.extendedProps.color;
        }
      },
      resourceLabelDidMount: function (arg) {
        arg.el.style.backgroundColor = arg.resource.extendedProps.color;
        arg.el.addEventListener("click", function (el) {
          if (el.target.tagName === "SPAN") {
            setStylist(arg.resource);
          }
        });
      },
      resourceLaneDidMount: function (arg) {
        arg.el.style.backgroundColor = arg.resource.extendedProps.color;
      },

    });

    onBeforeMount(() => {

      queryData.store = model?.user.storeId;

      Promise.all([model?.dispatch("store/find", queryData.store), loadStylists()])
        .then(([data]) => {
          Object.assign(store, data);
          if (store.BookingStartTime) store.BookingStartTimeDP = store.BookingStartTime.split('T')[1];
          if (store.BookingEndTime) store.BookingEndTimeDP = store.BookingEndTime.split('T')[1];
          console.log("Store:", data);
          store.Week = data.RecessPeriod.Durations;
          //console.log(store)
          if (
            store.BookingIntervalTime &&
            store.BookingIntervalTime !== "00:00:00"
          )
            calendarOptions.slotDuration = store.BookingIntervalTime;

          const startTime = $h.formatDate(store.BookingStartTime.substring(0, 19), "HH:mm"),
            endTime = $h.formatDate(store.BookingEndTime.substring(0, 19),
              "HH:mm")
          // Set Bussiness Hours
          calendarOptions.slotMinTime = startTime;
          calendarOptions.slotMaxTime = endTime;
          calendarOptions.businessHours = {
            daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
            startTime: startTime,
            endTime: endTime,
          };
         
        });

    })

    onMounted(() => {
      if (calendarRef.value) {
        console.log("calendarRef:", calendarRef.value);
        // @ts-ignore
        calendarApi.value = calendarRef.value.getApi();
      }
      today.value = getTodayDate();
      queryData.startDate = today.value;
      queryData.endDate = today.value + ' 23:59:59';
      refreshCal();
      

    });

    const getTodayDate = () => {
      var fullDate = new Date();
      var yyyy = fullDate.getFullYear();
      var MM = (fullDate.getMonth() + 1) >= 10 ? (fullDate.getMonth() + 1) : ("0" + (fullDate.getMonth() + 1));
      var dd = fullDate.getDate() < 10 ? ("0" + fullDate.getDate()) : fullDate.getDate();
      var today = yyyy + "/" + MM + "/" + dd;
      return today;
    }

    // 取得店內的設計師名單顯示於行事曆上    
    const loadStylists = () => {
      const condition = new Condition();
      condition.and("Employments.StoreId", Operator.Equal, model?.user.storeId);
      condition.and("Employee.ShowInCalendar", Operator.Equal, true);
      model?.dispatch("stylist/query", { page: 1, pageSize: 30, sortings: [new Sorting('Ordinal'), new Sorting('Employee.DataMode')], condition: condition }).then((resp) => {
        var stylistData = resp.data;
        console.log(stylistData);
        allResources.value = stylistData.map((ele, index) => {
          stylists.push({
            Value: index,
            Id: ele.Id,
            EId: ele.EmployeeId,
            Name: ele.Employee?.Person.Nickname,
          });
          return {
            id: ele.EmployeeId,
            dataId: ele.Id,
            title: ele.Employee?.Person.Nickname,
            color: ele.Employee?.Color,
            hide: false,
            // order: ele.Ordinal,
            index: index,
          };
        });

        console.log("New Resource:", allResources.val);
        calendarOptions.resources = [...allResources.value];
        stylistIds = stylistData.map((ele, index) => {
          return ele.Id;
        });
      });
    }

    // refresh calendar events
    const refreshCal = () => {
      const condition = new Condition();
      // if (queryData.store)
      //   condition.and("StoreId", Operator.Equal, queryData.store);
      if (queryData.startDate)
        condition.and("StartTime", Operator.GreaterThanOrEqual, queryData.startDate);
      if (queryData.endDate)
        condition.and("EndTime", Operator.LessThanOrEqual, queryData.endDate);
        condition.and("Type", Operator.NotEqual, 0);
      Promise.all([
        model?.dispatch("employeeEvent/query", {
          page: 1,
          pageSize: 100,
          condition: condition,
          sortings: { column: "CreatedTime", order: 1 }
        }),
      ])
        .then(([value]) => {
          let reservations = value.data;

          console.log('reservations', reservations);
          reservations = reservations.map((e, index) => {
            var startTime = new Date(e.StartTime);
            
            return {
              start: e.StartTime,
              end: $h.formatDate(
                new Date(startTime.getTime() + 30 * 60000),
                "yyyy-MM-ddTHH:mm"
              ),
              title: Object.values(enums.EventType).find(ele=> ele.Value === e.Type ).Name.charAt(0),
              stylist: "",
              icon: "",
              content: e.Description,
              class: "status-vacation",
              resourceId: e.EmployeeId,
              EventType: e.Type,
              eventId: e.Id,
              editable: e.Status !== 31 ? true : false,
              resourceEditable: e.Status !== 31 ? true : false,
            }
            
          })
          calendarApi.value.setOption('events', reservations.concat(recessDurationEvents));
        }).catch((failure) => {
          CloudFun.send("error", {
            subject: $t?.("message.operate-fail"),
            content: failure,
          });
          dataLoaded.value = false;
        })
    }

    const onAppointment = (isNewCustomer, eventType, customerData?) => {
      console.log('selectedEmployee', selectedEmployee)
      const reserveObject = {
        StartTime: $h.formatDate(new Date(selectedTime.value), "yyyy/MM/dd HH:mm"),
        Employee: selectedEmployee.value.Name,
        EmployeeId: selectedEmployee.value.EmployeeId,
        BirthDate: "",
        Customer: null,
        CustomerId: null,
        Type: eventType || 0,
        IsNew: isNewCustomer || false,
        Partition: "未指定",
      };
      console.log("reserveObject", reserveObject);
      if (isNewCustomer === true) {
        reserveObject.CustomerId = customerData.Id;
        reserveObject.Customer = customerData.Name;
        reserveObject.BirthDate = customerData.BirthDate;
      }


      appointmentModal.visible = true;
      appointmentModal.data = reserveObject;
      reserveVisible.value = false;
    }

    const dateChanged = (date) => {
      console.log("Date changed:", date);
      // var calendarApi = calendarApi.value;
      const newDate = date;
      calendarApi.value.gotoDate(newDate);
      // queryData.startDate = newDate
      // queryData.endDate = newDate + ' 23:59:59'
      // refreshCal();
    }

    const setStylist = (value) => {
      console.log(value.id);
      if (value.Id === selectedStylist) {
        selectedStylist.value = "all";
        calendarOptions.resources = allResources;
      } else {
        var i;

        allResources.forEach((e, index) => {
          if (e.id === value.id) {
            selectedStylist.value = value.Id;
            i = index;
          }
        });
        calendarOptions.resources = new Array(allResources[i]);
      }
    };

    return {
      currentDate,
      calendarRef,
      calendarOptions,
      vacationEventModal,
      dateChanged,
      appointmentModal,
      reserveVisible,
      onAppointment,
      refreshCal,
      dataLoaded
    }
  }

});

